import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Ejemplo from '../views/Ejemplo.vue'
import Socios from '../views/Socios.vue'
// import SeminarioRaya from '../views/SeminarioRaya.vue'
import SeminarioUday from '../views/SeminarioUday.vue'
import Socio from '../views/Socio.vue'
import PanelDeControl from '../views/PanelDeControl.vue'
import EditarSocio from '../views/EditarSocio.vue'
import EditarServicio from '../views/EditarServicio.vue'
import CrearPago from '../views/CrearPago.vue'
import EditarPago from '../views/EditarPago.vue'
import ListarPagos from '../views/ListarPagos.vue'
import ListarVentasEntregadas from '../views/ListarVentasEntregadas.vue'
import ListarVentasNoEntregadas from '../views/ListarVentasNoEntregadas.vue'
import EstadisticaVentas from '../views/EstadisticaVentas.vue'
import ListarServicios from '../views/ListarServicios.vue'
import CrearSocio from '../views/CrearSocio.vue'
import GenerarPassword from '../views/GenerarPassword.vue'
import RecuperarPassword from '../views/RecuperarPassword.vue'
import SocioNuevoPassword from '../views/SocioNuevoPassword.vue'
import Stock from '../views/Stock.vue'
import EditarStock from '../views/EditarStock.vue'
import CrearServicio from '../views/CrearServicio.vue'
import CrearVenta from '../views/CrearVenta.vue'
import Ventas from '../views/Ventas.vue'
import CargarEntrega from '../views/CargarEntrega.vue'
import MovimientoStock from '../views/MovimientoStock.vue'
import DatosPersonales from '../views/DatosPersonales.vue'
import HacermeSocio from '../views/HacermeSocio.vue'
import RespuestaPago from '../views/RespuestaPago.vue'
import Asociarse from '../views/Asociarse.vue'
import SoySocio from '../views/SoySocio.vue'
import PagoExitoso from '../views/PagoExitoso.vue'
import PagoExitosoCarrito from '../views/PagoExitosoCarrito.vue'
import PagoRechazadoCarrito from '../views/PagoRechazadoCarrito.vue'
import PagoRechazado from '../views/PagoRechazado.vue'
import PagoExitosoAsociarse from '../views/PagoExitosoAsociarse.vue'
import Difusion from '../views/Difusion.vue'
import PuntosDeEntrega from '../views/PuntosDeEntrega.vue'
import CrearEntregaVenta from '../views/CrearEntregaVenta.vue'
import SeminarioUdaySocio from '../views/SeminarioUdaySocio.vue'
// import MaterialDidactico from '../views/MaterialDidactico.vue'
import authService from '../services/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/ejemplo',
    name: 'Ejemplo',
    component: Ejemplo
  },
  {
    path: '/asociarse',
    name: 'Asociarse',
    component: Asociarse
  },
  {
    path: '/soy-socio',
    name: 'SoySocio',
    component: SoySocio
  },
  // {
  //   path: '/material-didactico',
  //   name: 'MaterialDidactico',
  //   component: MaterialDidactico
  // },
  {
    path: '/seminario-uday',
    name: 'SeminarioUday',
    component: SeminarioUday
  },
  {
    path: '/respuesta-pago',
    name: 'RespuestaPago',
    component: RespuestaPago
  },
  {
    path: '/hacerme-socio',
    name: 'HacermeSocio',
    component: HacermeSocio
  },
  {
    path: '/datos-personales',
    name: 'DatosPersonales',
    component: DatosPersonales,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pago-exitoso',
    name: 'PagoExitoso',
    component: PagoExitoso
  },
  {
    path: '/pago-carrito-exitoso',
    name: 'PagoExitosoCarrito',
    component: PagoExitosoCarrito
  },
  {
    path: '/pago-carrito-rechazado',
    name: 'PagoRechazadoCarrito',
    component: PagoRechazadoCarrito
  },
  {
    path: '/pago-rechazado',
    name: 'PagoRechazado',
    component: PagoRechazado
  },
  {
    path: '/pago-exitoso-asociarse',
    name: 'PagoExitosoAsociarse',
    component: PagoExitosoAsociarse
  },
  {
    path: '/socios',
    name: 'Socios',
    component: Socios,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/puntos-de-entrega',
    name: 'PuntosDeEntrega',
    component: PuntosDeEntrega,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/panel-de-control',
    name: 'PanelDeControl',
    component: PanelDeControl,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/difusion',
    name: 'Difusion',
    component: Difusion,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/seminario-uday-socio',
    name: 'SeminarioUdaySocio',
    component: SeminarioUdaySocio,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cargar-entrega/:id',
    name: 'CargarEntrega',
    component: CargarEntrega,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/ventas',
    name: 'Ventas',
    component: Ventas,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/socio/:id',
    name: 'Socio',
    component: Socio,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/crear-pago',
    name: 'CrearPago',
    component: CrearPago,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/crear-servicio',
    name: 'CrearServicio',
    component: CrearServicio,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/crear-venta',
    name: 'CrearVenta',
    component: CrearVenta,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/recuperar-password',
    name: 'RecuperarPassword',
    component: RecuperarPassword
  },
  {
    path: '/socio-nuevo-password',
    name: 'SocioNuevoPassword',
    component: SocioNuevoPassword
  },
  {
    path: '/generar-password/:id',
    name: 'GenerarPassword',
    component: GenerarPassword
  },
  {
    path: '/crear-socio',
    name: 'CrearSocio',
    component: CrearSocio,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/listar-pagos',
    name: 'ListarPagos',
    component: ListarPagos,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/estadistica-ventas',
    name: 'EstadisticaVentas',
    component: EstadisticaVentas,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/listar-ventas-entregadas',
    name: 'ListarVentasEntregadas',
    component: ListarVentasEntregadas,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/listar-ventas-no-entregadas',
    name: 'ListarVentasNoEntregadas',
    component: ListarVentasNoEntregadas,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/listar-servicios',
    name: 'ListarServicios',
    component: ListarServicios,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/stock',
    name: 'Stock',
    component: Stock,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/movimiento-stock',
    name: 'MovimientoStock',
    component: MovimientoStock,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/editar-stock/:id',
    name: 'EditarStock',
    component: EditarStock,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/editar-pago/:id',
    name: 'EditarPago',
    component: EditarPago,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/crear-entrega-venta/:id',
    name: 'CrearEntregaVenta',
    component: CrearEntregaVenta,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/editar-socio',
    name: 'EditarSocio',
    component: EditarSocio,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editar-servicio/:id',
    name: 'EditarServicio',
    component: EditarServicio,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes
})
// Registramos el navigation guard global, para poder agregar lógica de autenticación.
router.beforeEach((to, from, next) => {
  // Esto se ejecuta antes del cambio de una ruta.
  // Así que vmaos a preguntar si el usuario está autenticado al servicio de autencación, pero solo
  // si la ruta requiere que el usuario lo esté.
  // "to" contiene un objeto "ruta" con todos los datos de ella.
  // Entre sus propiedades figura "matched", que es un array de las rutas que matchean con la nueva URL.
  // De esas, vamos a verificar si al menos una requiere que el usuario esté autenticado, con ayuda
  // de la función nativa de js "some".
  if (to.matched.some(route => route.meta.requiresAuth === true)) {
    if (!authService.isLogged()) {
      // Redireccionamos al usuario al login.
      next({
        path: '/'
      })
    } else {
      // Tenemos que agregar los next() en cada else, no podemos simplemente dejarlo "decantar" al último.
      next()
    }
  } else if (to.matched.some(route => route.meta.requiresAdmin === true)) {
    if (!authService.isLogged2()) {
      // Redireccionamos al usuario al login.
      next({
        path: '/datos-personales'
      })
    } else {
      // Tenemos que agregar los next() en cada else, no podemos simplemente dejarlo "decantar" al último.
      next()
    }
  } else {
    next()
  }
})
export default router
