// const API = 'http://localhost:8888/AAYI_API/api/public/api'
// const PATH_IMG = 'http://localhost:8888/AAYI_API/api/storage/app/public/img'

// const API = 'https://prueba.juandescalzoyoga.com.ar/api'
// const PATH_IMG = 'https://prueba.juandescalzoyoga.com.ar/storage/app/public/img'

const API = 'https://api.asociacioniyengar.com.ar/api'
const PATH_IMG = 'https://api.asociacioniyengar.com.ar/storage/app/public/img'

export { API, PATH_IMG }
