import { API } from './../constants'
import authService from './auth.js'

let ventas = null
// let venta = null
let seminarios = null
// let cantidadProductosVendidos = null
// let ventasTotales = null
// let totalFacturadoCarrito = null

const ventasService = {

  async traerServiciosTodosConStock () {
    const fetchResponse = await fetch(`${API}/servicios-stock`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    ventas = respuesta.data
    return [...ventas]
  },

  async traerVentasTodas () {
    const fetchResponse = await fetch(`${API}/ventas`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    ventas = respuesta.data
    return [...ventas]
  },

  async traerProductosVendidosEntregados () {
    const fetchResponse = await fetch(`${API}/ventas-lista-entregados`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    return { ...respuesta }
  },

  async traerProductosVendidosNoEntregados () {
    const fetchResponse = await fetch(`${API}/ventas-lista-no-entregados`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    return { ...respuesta }
  },

  async traerProductosVendidos () {
    const fetchResponse = await fetch(`${API}/ventas-totales`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    return { ...respuesta }
    // ventasTotales = respuesta.data
    // return [...ventasTotales]
  },
  async traerVentaPorId (id) {
    const fetchResponse = await fetch(`${API}/venta/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    return { ...respuesta }
    // venta = respuesta.data
    // return [...venta]
  },

  async traerAsistenciaSeminariosPorAsociadoId (id) {
    const fetchResponse = await fetch(`${API}/asistencia-seminarios/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    seminarios = respuesta.data
    return [...seminarios]
  },

  crearVenta (data) {
    return fetch(`${API}/crear-venta`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  }

}

export default ventasService
