<template>
  <div class="contenedor-difusion">
    <!-- <Header2/> -->
    <section class="contenedor-c">
      <div class="contenedor-titulo">
        <h1>Seminario de Yoga Iyengar con Uday Bhosale</h1>
        <div class="barra"></div>
      </div>
      <section class="contenedor-login">
        <div class="contenedor-difusion-imagen">
          <img src="@/assets/uday.jpeg" alt="Uday Bhosale">
        </div>
        <div class="contenedor-difusion-text">
          <p class="warning_no_socio">Esta es la página de inscripción al seminario de Yoga Iyengar con Uday Bhosale para los NO SOCIXS de la AAYI</p>

          <p>Luego del hermoso encuentro con <b>Raya Una Datta</b>, se viene otro gran evento para nuestra comunidad. <b>Uday Bhosale</b> vendrá a la Argentina en el marco de su gira por América<b> "La Alegría del Yoga Iyengar"</b> (The Joy of Iyengar Yoga).</p>

          <p>Uday Bhosale vive en Reading, Reino Unido. Da clases para todos los niveles y capacidades en Reading, Londres, y Henley-on-Thames. Su maestro de artes marciales lo introdujo al <b>método Iyengar</b>. Comenzó aprendiendo con <b>Guruji, Geetaji y Prashantji</b> en 1999. En el <b>Instituto de Yoga Iyengar</b> en Pune, condujo las clases para niños (7-15 años), clases para alumnos intermedios y principiantes. Asistió regularmente en las clases médicas (terapéuticas) en RIMYI, que se llevaban a cabo 3 veces por semana. En 2015 fue maestro invitado en la <b>21a Convención Nacional Francesa de Yoga Iyengar</b>. Desde entonces ha conducido talleres y retiros en el Reino Unido, Francia, Irlanda, Alemania. <b>Uday Bhosale</b> fue enviado a China por el gobierno de India para el Día Internacional del Yoga en 2016, en representación del <b>RIMYI</b>. Uday ha conducido Workshops de Yoga por todo el Reino Unido y por toda Europa.</p>

          <h2>Seminario General (con solo 1 año o más de práctica)</h2>
          <p><b>Fechas: 28 & 29 de Septiembre</b> 2024</p>

            <!-- <p class="margin_left_uday">Viernes 27: 16-19 hs</p> -->
            <!-- <p class="margin_left_uday">Sábado 28: 9-12 hs y 14-16 hs</p> -->
            <p class="margin_left_uday">Sábado 28: 9:30-12 hs</p>
            <p class="margin_left_uday">Domingo 29: 9:30-12 hs</p>

          <p><b>Precio:</b> Un pago de $93.000 o dos pagos (Agosto y Septiembre) de $ 46.500</p>

          <h2>Seminario Completo (4 años o más de práctica)</h2>
          <p><b>Fechas: 27, 28 & 29 de Septiembre</b> 2024</p>

            <p class="margin_left_uday">Viernes 27: 16:30-19:30 hs</p>
            <p class="margin_left_uday">Sábado 28: 9:30-12 hs y 14-16:30 hs</p>
            <p class="margin_left_uday">Sábado 28: 9:30-12 hs</p>
            <p class="margin_left_uday">Domingo 29: 9:30-12 hs</p>

          <p><b>Precio:</b> Un pago de $204.000 o dos pagos (Agosto y Septiembre) de $ 102.000</p>
          <p><b>Lugar:</b> Facultad de Ciencias Económicas, Uriburu 781, CABA</p>
          <p><b>“Al asociarte, accedes a los descuentos (20%) instantáneamente”</b></p>

          <p class="register_link_seminario"><a href="https://asociacioniyengar.com.ar/beneficios.php" target="_blank">Beneficios al Socix</a></p>

          <p class="register_link_seminario">¿Todavía no sos socio? <router-link to="/asociarse">Asociate</router-link></p>

          <!-- <p><b>Requisito:</b> Sólo <b>UN AÑO</b> de clases regulares!</p>

          <p><b>Importante!</b></p> -->

          <!-- <p>Antes que pagues, te contamos que podes asociarte y obtener los beneficios de descuentos en materiales didácticos y eventos de la AAYI.</p>

          <p>El valor a Socix es ... un pago de $ 77.500 o en dos cuotas de $ 38.750</p>

          <p>Y recorda que si tenes más de 4 de práctica, asociándote podes sumarte al seminario completo!!!</p>

          <p>Viernes de 16:30 a 19:30, Sábado de 9:30 a 12 y de 14 a 16:30, Domingo de 9:30 a 12.  Valor a Socix ... un pago de $ 170.500 y dos cuotas ( Agosto y Septiembre) de $ 85.250</p> -->

          <!-- <p>La <b>Convención</b> constará de <b>dos partes</b>: una parte para <b>profesores</b>, personas en <b>mentoría</b> y personas con más de <b>5 años de práctica</b> (con carta de recomendación), y dos días para <b>alumnos</b> en general con <b>1 año de clases.</b></p>
          <p>Para <b>profesorxs, personas en mentoría y alumnos</b> con <b>5 años de práctica</b>: viernes <b>24</b>, lunes <b>27</b> y martes <b>28 de mayo 2024</b>.</p>
          <p><b>General</b> (<b>alumnos</b> con mínimo de <b>1 año de clases</b> regulares): sábado <b>25</b> y domingo <b>26 de mayo 2024</b>.</p>
          <p>⚠️ Para asistir el viernes, lunes y martes es condición asistir también el fin de semana. Pero si, por el contrario, profesores, mentoreados, etc necesitan asistir solamente el fin de semana, pueden hacerlo. ⚠️</p> -->

          <!-- <p>👀 Invitamos enfáticamente a la comunidad profesores y personas formándose a participar de este evento único y tan enriquecedor para nuestra comunidad. 👀</p> -->

          <div class="seminario-price-box-no-socio">
            <h2 v-if="mostrarMostrarDniForm">Si NO sos SOCIX de la AAYI, ingresa tu dni</h2>
            <h2 v-if="mostrarForm">Por favor, completa tus datos antes de pagar el seminario</h2>
            <!-- <p v-if="mostrar" class="warning_no_profe">Por favor, completa el DNI y el domicilio en tu perfil para poder realizar tu pago</p> -->
            <form
              action="#"
              method="post"
              @submit.prevent="checkDnioNoSocio(practicante)"
              class="form_seminario_no_socio"
              v-if="mostrarMostrarDniForm"
            >
              <div class="form_section_seminario_no_socio">
                <label for="dni">DNI</label>
                <input
                  type="text"
                  name="dni"
                  id="dni"
                  v-model="practicante.dni"
                  :disabled="cargando"
                >
                <div
                class="form_mensaje_error_no_socio"
                  v-if="errores.dni !== null"
                >
                  {{ errores.dni[0] }}
                </div>
              </div>
              <p class="terminos_condiciones_mensaje_error" v-if="mostrarMensajeYaSocio">El dni ingresado es de un socio de la AAYI. Por favor, ingresa al portal para realizar la inscripción al seminario</p>
              <div class="seminario-texto-box" v-if="mostrarMensajeYaIncripto">
                {{ this.texto }}
              </div>
              <button type="submit" class="boton_guardar">Siguiente</button>
            </form>
            <form
              action="#"
              method="post"
              @submit.prevent="pagoSeminarioNoSocio(practicante)"
              class="form_seminario_no_socio"
              v-if="mostrarForm"
            >
              <div class="form_section_seminario_no_socio">
                <label for="nombre">Nombre</label>
                <input
                  type="text"
                  name="nombre"
                  id="nombre"
                  v-model="practicante.nombre"
                  :disabled="cargando"
                >
                <div
                class="form_mensaje_error_no_socio"
                  v-if="errores.nombre !== null"
                >
                  {{ errores.nombre[0] }}
                </div>
              </div>
              <div class="form_section_seminario_no_socio">
                <label for="apellido">Apellido</label>
                <input
                  type="text"
                  name="apellido"
                  id="apellido"
                  v-model="practicante.apellido"
                  :disabled="cargando"
                >
                <div
                class="form_mensaje_error_no_socio"
                  v-if="errores.apellido !== null"
                >
                  {{ errores.apellido[0] }}
                </div>
              </div>
              <div class="form_section_seminario_no_socio">
                <label for="email">Email</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  v-model="practicante.email"
                  :disabled="cargando"
                >
                <div
                class="form_mensaje_error_no_socio"
                  v-if="errores.email !== null"
                >
                  {{ errores.email[0] }}
                </div>
              </div>
              <div class="form_section_seminario_no_socio">
                <label for="dni">DNI</label>
                <input
                  type="text"
                  name="dni"
                  id="dni"
                  v-model="practicante.dni"
                  :disabled="cargando"
                >
                <div
                class="form_mensaje_error_no_socio"
                  v-if="errores.dni !== null"
                >
                  {{ errores.dni[0] }}
                </div>
              </div>
              <div class="form_section_seminario_no_socio">
                <label for="direccion_1">Dirección</label>
                <input
                  type="text"
                  name="direccion_1"
                  id="direccion_1"
                  v-model="practicante.direccion_1"
                  :disabled="cargando"
                >
                <div
                class="form_mensaje_error_no_socio"
                  v-if="errores.direccion_1 !== null"
                >
                  {{ errores.direccion_1[0] }}
                </div>
              </div>
              <div class="form_section_seminario_no_socio">
                <label for="ciudad_1">Ciudad / Localidad / Barrio</label>
                <input
                  type="text"
                  name="ciudad_1"
                  id="ciudad_1"
                  v-model="practicante.ciudad_1"
                  :disabled="cargando"
                >
                <div
                class="form_mensaje_error_no_socio"
                  v-if="errores.ciudad_1 !== null"
                >
                  {{ errores.ciudad_1[0] }}
                </div>
              </div>
              <div class="form_section_seminario_no_socio">
                <label for="provincia_id_1">Provincia</label>
                <select
                  id="provincia_id_1"
                  name="provincia_id_1"
                  class="form minimal"
                  v-model.number="practicante.provincia_id_1"
                  :disabled="cargando"
                >
                  <option
                    v-for="provincia in provincias"
                    :value="provincia.provincia_id"
                    :key="provincia.provincia_id"
                  >
                    {{ provincia.nombre }}
                  </option>
                </select>
                <div
                class="form_mensaje_error_no_socio"
                  v-if="errores.provincia_id_1 !== null"
                >
                  {{ errores.provincia_id_1[0] }}
                </div>
              </div>
              <div class="form_section_seminario_no_socio">
                <label for="pais_id_1">País</label>
                <select
                  id="pais_id_1"
                  name="pais_id_1"
                  class="form minimal"
                  v-model.number="practicante.pais_id_1"
                  :disabled="cargando"
                >
                  <option
                    v-for="pais in paises"
                    :value="pais.pais_id"
                    :key="pais.pais_id"
                  >
                    {{ pais.nombre }}
                  </option>
                </select>
                <div
                class="form_mensaje_error_no_socio"
                  v-if="errores.pais_id_1 !== null"
                >
                  {{ errores.pais_id_1[0] }}
                </div>
              </div>
              <div class="form_section_seminario_no_socio">
                <label for="toma_clase">Mi profe es</label>
                <!-- <input
                  type="text"
                  name="toma_clase"
                  id="toma_clase"
                  v-model="socio.toma_clase"
                  :disabled="cargando"
                > -->
                <!-- <select
                  id="toma_clase"
                  name="toma_clase"
                  class="form minimal"
                  v-model.number="socio.toma_clase"
                  :disabled="cargando"
                >
                  <option
                    v-for="profesor in profesores"
                    :value="profesor.persona_id"
                    :key="profesor.persona_id"
                  >
                    {{ profesor.nombre }} {{ profesor.apellido }}
                  </option>
                </select> -->
                <div class="contenedor_select_buscador buscador_full_width">
                  <v-select
                    class="select_buscador minimal select_buscador_extra"
                    v-model.number="practicante.toma_clase"
                    label="item_data"
                    :reduce="profesores => profesores.persona_id"
                    :options="profesores"
                  >
                    <span slot="no-options" @click="$refs.select.open = false" class="select_buscador_warning">
                      <p>El profe ingresado no figura en nuestros registros. <br> Por favor selecccione la opción <span>* Otro *</span></p>
                    </span>
                  </v-select>
                </div>
                <div
                class="form_mensaje_error_no_socio"
                  v-if="errores.toma_clase !== null"
                >
                  {{ errores.toma_clase[0] }}
                </div>
              </div>
              <div class="contenedor_radio_button">
                <div v-if="mostrarBotonesSeminarioGeneral">
                  <h2>Seminario General <span>(Sábado y Domingo de 9:30 a 12 hs)</span></h2>
                  <div class="radio_button_individual" v-if="mostrarBotonesNoCuotaDosSeminarioGeneral">
                    <input type="radio" id="un_pago_general" v-model="practicante.opcion" value="1">
                    <label for="un_pago_general"><b> 1 Pago</b> - <b>AR$ 93.000</b></label>
                  </div>
                  <div class="radio_button_individual" v-if="mostrarBotonesNoCuotaDosSeminarioGeneral">
                    <input type="radio" id="cuota_1_general" v-model="practicante.opcion" value="2">
                    <label for="cuota_1_general"><b> Cuota #1/2</b> - <b>AR$ 46.500</b></label>
                  </div>
                  <div class="radio_button_individual" v-if="mostrarBotonCuotaDosSeminarioGeneral">
                    <input type="radio" id="cuota_2_general" v-model="practicante.opcion" value="3">
                    <label for="cuota_2_general"><b> Cuota #2/2</b> - <b>AR$ 46.500</b></label>
                  </div>
                </div>
                <div v-if="mostrarBotonesSeminarioCompleto">
                  <h2>Seminario Completo <span>(Viernes de 16:30 a 19:30 hs,  Sábado de 9:30 a 12 hs y 14 a 16:30 hs y Domingo de 9:30 a 12 hs)</span></h2>
                  <div class="radio_button_individual" v-if="mostrarBotonesNoCuotaDosSeminarioCompleto">
                    <input type="radio" id="un_pago_completo" v-model="practicante.opcion" value="4">
                    <label for="un_pago_completo"><b> 1 Pago</b> - <b>AR$ 204.000</b></label>
                  </div>
                  <div class="radio_button_individual" v-if="mostrarBotonesNoCuotaDosSeminarioCompleto">
                    <input type="radio" id="cuota_1_completo" v-model="practicante.opcion" value="5">
                    <label for="cuota_1_completo"><b> Cuota #1/2</b> - <b>AR$ 102.000</b></label>
                  </div>
                  <div class="radio_button_individual" v-if="mostrarBotonCuotaDosSeminarioCompleto">
                    <input type="radio" id="cuota_2_completo" v-model="practicante.opcion" value="6">
                    <label for="cuota_2_completo"><b> Cuota #2/2</b> - <b>AR$ 102.000</b></label>
                  </div>
                </div>
              </div>
              <div
                class="form_mensaje_error_no_socio"
                v-if="errores.opcion !== null"
              >
                {{ errores.opcion[0] }}
              </div>
              <!-- <h2>COMPLETA EL FORMULARIO ANTES DE PAGAR</h2>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfjmNxgcHJUPOVNY1ybmdDOMpDfIIymBfDxS4bYJAaQT4BBMA/viewform" target="_blank"  class="seminario-price-box-boton-form"><b>Formulario</b></a>
              <div class="contenedor_checkbox">
                <input
                  type="checkbox"
                  id="form"
                  v-model="form"
                  @change="ckeckFormulario()"
                >
                <label for="form">Confirmo que he completado el formulario</label>
              </div> -->
              <p class="terminos_condiciones_mensaje_error" v-if="mostrarMensajeFormulario">Por favor, completa el formulario antes de pagar</p>
              <button type="submit" class="boton_guardar">Pagar Seminario</button>
            </form>
          </div>
          <p>Consultas a <a href="mailto:seminarios@asociacioniyengar.com.ar" target="_blank"><b>seminarios@asociacioniyengar.com.ar</b></a></p>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import 'vue-select/dist/vue-select.css'
import paisesService from '../services/paises'
import provinciasService from '../services/provincias'
import personasService from '../services/personas'
import asociadoService from '../services/asociado'
import pagosService from '../services/pagos'
const mercadopago = require('mercadopago')
export default {
  name: 'SeminarioRaya',
  components: {
    // LoginForm,
    // BaseNotification2
  },
  data: function () {
    return {
      form: false,
      texto: null,
      paises: [],
      provincias: [],
      profesores: [],
      mostrarMensajeFormulario: false,
      mostrarMensajeYaSocio: false,
      mostrarMensajePimeraVez: false,
      mostrarMensajeReincidente: false,
      mostrarMensajeYaIncripto: false,
      mostrarBotonesSeminarioCompleto: true,
      mostrarBotonesNoCuotaDosSeminarioCompleto: true,
      mostrarBotonCuotaDosSeminarioCompleto: false,
      mostrarBotonesSeminarioGeneral: true,
      mostrarBotonCuotaDosSeminarioGeneral: false,
      mostrarBotonesNoCuotaDosSeminarioGeneral: true,
      mostrarMensajeFaltaCuotaDos: false,
      mostrarForm: false,
      mostrarMostrarDniForm: true,
      practicante: {
        persona_id: null,
        nombre: null,
        apellido: null,
        email: null,
        dni: null,
        toma_clase: null,
        perfil_id: 4,
        cuota_id: null,
        servicio_id: null,
        seminario: 'si',
        reincidente: 'no',
        ciudad_1: null,
        direccion_1: null,
        provincia_id_1: null,
        pais_id_1: null,
        opcion: null
      },
      errores: {
        nombre: null,
        apellido: null,
        email: null,
        dni: null,
        ciudad_1: null,
        direccion_1: null,
        provincia_id_1: null,
        pais_id_1: null,
        opcion: null,
        toma_clase: null
      },
      cargando: false,
      mostrar: false,
      socio: [],
      linkMercadoPago: {
        link: null
      }
    }
  },
  methods: {
    ckeckFormulario () {
      if (this.form === false) {
        this.mostrarMensajeFormulario = true
      } else {
        this.mostrarMensajeFormulario = false
      }
    },
    checkDnioNoSocio (practicante) {
      this.mostrarMensajeYaSocio = false
      console.log(practicante)
      if (this.cargando) {
        return null
      }
      this.errores = {
        nombre: null,
        apellido: null,
        email: null,
        dni: null,
        ciudad_1: null,
        direccion_1: null,
        provincia_id_1: null,
        pais_id_1: null,
        toma_clase: null,
        opcion: null
      }
      personasService
        .validarSocioSeminario(practicante)
        .then(respuesta => {
          if (respuesta.success) {
            console.log('siiiii')
            this.mostrarMensajeYaSocio = true
          } else {
            console.log(respuesta.errors)
            // console.log(Object.keys(respuesta.errors).length)
            if (respuesta.errors === undefined) {
              if (respuesta.info === 'primera') {
                this.mostrarMensajePimeraVez = true
                this.mostrarForm = true
                this.mostrarMostrarDniForm = false
              }

              if (respuesta.info === 'reincidente') {
                this.mostrarMensajeReincidente = true
                this.practicante = respuesta.data[0]
                this.practicante.seminario = 'si'
                this.practicante.reincidente = 'si'
                console.log(respuesta.data[0])
                pagosService
                  .checkPago(respuesta.data[0].persona_id)
                  .then(respuesta => {
                    console.log('Pago:', respuesta.data)
                    if (respuesta.data.info === 'no_pago') {
                      this.mostrarMensajePimeraVez = true
                      this.mostrarForm = true
                      this.mostrarMostrarDniForm = false
                    }
                    if (respuesta.data.mostrar_botones === 'no') {
                      console.log('eeeeeeerrrr')
                      console.log(respuesta.data.mensaje)
                      this.mostrarMensajeYaIncripto = true
                      this.texto = respuesta.data.mensaje
                    }
                    if (respuesta.data.mostrar_botones === 'si') {
                      this.mostrarForm = true
                      if (respuesta.data.seminario === 'completo') {
                        this.mostrarBotonesSeminarioGeneral = false
                        this.mostrarBotonCuotaDosSeminarioCompleto = true
                        this.mostrarBotonesNoCuotaDosSeminarioCompleto = false
                      } else {
                        this.mostrarBotonesSeminarioCompleto = false
                        this.mostrarBotonCuotaDosSeminarioGeneral = true
                        this.mostrarBotonesNoCuotaDosSeminarioGeneral = false
                      }
                      // this.mostrarMensajeFaltaCuotaDos = true
                      // this.textoFaltaCuotaDos = respuesta.data.mensaje
                      this.mostrarMostrarDniForm = false
                    }
                  })
              }
            }
            console.log('noooo')
            this.errores = {
              nombre: null,
              apellido: null,
              email: null,
              dni: null,
              ciudad_1: null,
              direccion_1: null,
              provincia_id_1: null,
              pais_id_1: null,
              toma_clase: null,
              opcion: null,
              ...respuesta.errors
            }
          }
        })
    },
    pagoSeminarioNoSocio (practicante) {
      // if (this.form === false) {
      //   this.mostrarMensajeFormulario = true
      //   return
      // }
      if (this.practicante.opcion === '1') {
        this.practicante.servicio_id = 11
        this.practicante.cuota_id = 17
      }
      if (this.practicante.opcion === '2') {
        this.practicante.servicio_id = 12
        this.practicante.cuota_id = 18
      }
      if (this.practicante.opcion === '3') {
        this.practicante.servicio_id = 12
        this.practicante.cuota_id = 19
      }
      if (this.practicante.opcion === '4') {
        this.practicante.servicio_id = 13
        this.practicante.cuota_id = 26
      }
      if (this.practicante.opcion === '5') {
        this.practicante.servicio_id = 14
        this.practicante.cuota_id = 27
      }
      if (this.practicante.opcion === '6') {
        this.practicante.servicio_id = 14
        this.practicante.cuota_id = 28
      }
      console.log(practicante)
      if (this.cargando) {
        return null
      }
      this.errores = {
        nombre: null,
        apellido: null,
        email: null,
        dni: null,
        ciudad_1: null,
        direccion_1: null,
        provincia_id_1: null,
        pais_id_1: null,
        toma_clase: null,
        opcion: null
      }
      personasService
        .asociarse(practicante)
        .then(respuesta => {
          if (respuesta.success) {
            console.log('siiiii')
            console.log(this.practicante.opcion)
            console.log(this.practicante.cuota_id)
            pagosService
              .traerCuotaPorId(this.practicante.cuota_id)
              .then(respuesta => {
                console.log('Cuota:', respuesta)
                const preference = {
                  back_urls: {
                    failure: 'https://portal.asociacioniyengar.com.ar/#/pago-rechazado',
                    success: 'https://portal.asociacioniyengar.com.ar/#/pago-exitoso'
                  },
                  items: [
                    {
                      title: String(respuesta[0].nombre),
                      unit_price: Number(respuesta[0].precio),
                      quantity: 1
                    }
                  ],
                  metadata: {
                    persona_id: this.practicante.persona_id,
                    nombre: this.practicante.nombre,
                    apellido: this.practicante.apellido,
                    email: this.practicante.email,
                    perfil_id: this.practicante.perfil_id,
                    dni: this.practicante.dni,
                    direccion_1: this.practicante.direccion_1,
                    ciudad_1: this.practicante.ciudad_1,
                    provincia_id_1: this.practicante.provincia_id_1,
                    pais_id_1: this.practicante.pais_id_1,
                    toma_clase: this.practicante.toma_clase,
                    concepto_id: 5,
                    forma_de_pago_id: 3,
                    moneda_id: 1,
                    servicio_id: Number(respuesta[0].servicio_id),
                    cuota_id: Number(respuesta[0].cuota_id),
                    pago_concepto: 'PAGO SEMINARIO NO SOCIO',
                    descripcion: String(respuesta[0].nombre),
                    fecha_inicio: String(respuesta[0].fecha_inicio),
                    fecha_finalizacion: String(respuesta[0].fecha_finalizacion),
                    // precio_individual: Number(this.cuotaSocial[this.cuotaSocial.length - 1].precio_individual),
                    precio: Number(respuesta[0].precio)
                  },
                  payer: {
                    name: this.practicante.nombre,
                    surname: this.practicante.apellido
                  },
                  notification_url: 'https://api.asociacioniyengar.com.ar/api/webhooks',
                  // notification_url: 'https://c74e-190-194-214-138.ngrok-free.app/AAYI_API/api/public/api/webhooks',
                  binary_mode: true,
                  auto_return: 'approved',
                  external_reference: this.practicante.nombre + ' ' + this.practicante.apellido + ' - ' + new Date().getTime().toString(),
                  statement_descriptor: 'Asociación Argentina de Yoga Iyengar'
                }
                mercadopago.configure({
                  access_token: 'APP_USR-6447832264591121-032711-ad6690b0c38d8e10440c46daab8b7204-1323684790'
                  // Credenciales de Prueba
                  // access_token: 'APP_USR-2339860730955398-032713-925884898e35a8f3be76c84328e45b4f-1339687465'
                })
                mercadopago.preferences
                  .create(preference)
                  .then(response => {
                    console.log('mercadopago: ', response.body)
                    console.log(new Date().getTime())
                    // console.log(this.linkMercadoPago.link)
                    this.linkMercadoPago.link = response.body.init_point
                    // En esta instancia deberás asignar el valor dentro de response.body.id por el ID de preferencia solicitado en el siguiente paso
                    window.location.href = this.linkMercadoPago.link
                  })
                  .catch(function (error) {
                    console.log('MP ERROR!!!!!')
                    console.log(error)
                  })
              })
          } else {
            console.log('noooo')
            this.errores = {
              nombre: null,
              apellido: null,
              email: null,
              dni: null,
              ciudad_1: null,
              direccion_1: null,
              provincia_id_1: null,
              pais_id_1: null,
              opcion: null,
              toma_clase: null,
              ...respuesta.errors
            }
          }
        })
    }
  },
  mounted: function () {
    paisesService
      .traerPaisesTodos()
      .then(respuesta => {
        // this.cargando = false
        this.paises = respuesta
        console.log('Paises:', respuesta)
      })
    provinciasService
      .traerProvinciasTodas()
      .then(respuesta => {
        // this.cargando = false
        this.provincias = respuesta
        console.log('Provincias:', respuesta)
      })
    asociadoService
      .traerProfesoresFormadoresTodos()
      .then(respuesta => {
        // this.cargando = false
        this.profesores = respuesta
        console.log('Profesores TODOS:', respuesta)
        this.profesores.map(function (x) {
          x.item_data = x.nombre + ' ' + x.apellido
        })
      })
  }
}
</script>
<style>

.margin_left_uday {
  margin-left: 15px !important;
}

</style>
