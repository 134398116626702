<template>
  <div class="contenedor-lista-pagos">
    <BaseNotification2
      v-if="store.status.msg != null"
      :type="store.status.type"
      :text="store.status.msg"
      :closable="store.status.closable"
      @close="borrarNotificacion()"
    />
    <form
      action="#"
      method="post"
      @submit.prevent="buscar(pago)"
    >
      <div class="form_section">
        <label for="persona_id">Persona</label>
        <div class="contenedor_select_buscador">
          <v-select
            class="select_buscador minimal"
            v-model.number="pago.persona_id"
            label="item_data"
            :reduce="socios => socios.persona_id"
            :options="socios"
            :disabled="cargando"
          >
            <span slot="no-options" @click="$refs.select.open = false" class="select_buscador_warning">
              <p>La persona ingresada no figura en nuestros registros.</p>
            </span>
          </v-select>
        </div>
      </div>
      <div class="form_section">
        <label for="servicio_id">Servicio</label>
        <select
          id="servicio_id"
          name="servicio_id"
          class="form minimal"
          v-model.number="pago.servicio_id"
          :disabled="cargando"
        >
          <option value="null">Todas las opciones</option>
          <option
            v-for="servicio in servicios"
            :value="servicio.servicio_id"
            :key="servicio.servicio_id"
          >
            {{ servicio.nombre }}
          </option>
        </select>
      </div>
      <div class="form_section">
        <label for="concepto_id">Concepto</label>
        <select
          id="concepto_id"
          name="concepto_id"
          class="form minimal"
          v-model.number="pago.concepto_id"
          :disabled="cargando"
        >
          <option value="null">Todas las opciones</option>
          <option
            v-for="concepto in conceptos"
            :value="concepto.concepto_id"
            :key="concepto.concepto_id"
          >
            {{ concepto.nombre }}
          </option>
        </select>
      </div>
      <div class="form_section">
        <label for="fecha_inicio">Desde</label>
        <input
          type="date"
          name="fecha_inicio"
          id="fecha_inicio"
          v-model="pago.fecha_inicio"
          :disabled="cargando"
        >
        <div
        class="form_mensaje_error_pago_lista"
          v-if="errores.fecha_inicio !== null"
        >
          {{ errores.fecha_inicio[0] }}
        </div>
      </div>
      <div class="form_section">
        <label for="fecha_finalizacion">Hasta</label>
        <input
          type="date"
          name="fecha_finalizacion"
          id="fecha_finalizacion"
          v-model="pago.fecha_finalizacion"
          :disabled="cargando"
        >
        <div
        class="form_mensaje_error_pago_lista"
          v-if="errores.fecha_finalizacion !== null"
        >
          {{ errores.fecha_finalizacion[0] }}
        </div>
      </div>
      <button type="submit" class="boton_guardar">Buscar</button>
    </form>
    <p v-if="this.pagos.length == 0" class="no-resultado">No se encontraron resultados</p>
      <div v-else>
        <BaseLoader
          v-if="cargando"
        />
        <div v-else>
          <div class="contenedor_socios_lista" >
            <button
              class="boton_exportar"
              @click="exportData"
            >
              Exportar a Excel
            </button>
            <table id="tabla-socio-lista">
              <thead>
                <th>Fecha</th>
                <th>Persona</th>
                <th>Monto</th>
                <th>Forma de Pago</th>
                <th>Moneda</th>
                <th>Servicio</th>
                <th>Cuota #</th>
                <th>Concepto</th>
                <th>Descripción</th>
                <th class="nofuente gradient_2"></th>
              </thead>
              <tbody>
                <PagosListaItem
                  v-for="pago in pagosPaginado"
                  :pago="pago"
                  :key="pago.pago_id"
                  @refrescar="refrescarPagos"
                  @refrescar2="borrarNotificacion"
                ></PagosListaItem>
              </tbody>
            </table>
          </div>
          <div class="contenedor_paginado" v-if="this.pagos.length > 20">
              <button
                @click="firstPage"
                class="boton_paginado_numerico salto_fin_principio"
              >
                <span>&#8739;</span>
                <span>&#60;</span>
              </button>
              <button
                :disabled="currentPage === 1"
                @click="prevPage"
                :class="{ disabled: currentPage === 1 }"
                class="boton_paginado_numerico boton_paginado_anterior"
              >
                <span>&#60;</span>
              </button>
              <button
                v-for="pageNumber in bulletsPaginado"
                :key="pageNumber"
                :class="{ pagina_activa: currentPage === pageNumber }"
                class="boton_paginado_numerico"
                @click="goToPage(pageNumber)"
              >
                {{ pageNumber }}
              </button>
              <div
                v-if="this.mostrarLastPageBullets"
                class="paginado_contenedor_puntitos"
              >
                <span> .... </span>
                <button
                  class="boton_paginado_numerico"
                  @click="goToPage(pageCount)"
                >
                  {{ pageCount }}
                </button>
              </div>
              <button
                :disabled="currentPage === pageCount"
                :class="{ disabled: currentPage === pageCount }"
                @click="nextPage"
                class="boton_paginado_numerico boton_paginado_siguiente"
              >
                <span>&#62;</span>
              </button>
              <button
                @click="lastPage"
                class="boton_paginado_numerico boton_paginado_anterior"
              >
                <span>&#62;</span>
                <span>&#8739;</span>
              </button>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import store from '../store'
import BaseNotification2 from '../components/BaseNotification2'
import PagosListaItem from '../components/PagosListaItem.vue'
import asociadoService from '../services/asociado'
// import Header2 from '../components/Header2.vue'
import pagosService from '../services/pagos'
import serviciosService from '../services/servicios'
import BaseLoader from '../components/BaseLoader.vue'
// import exportFromJSON from 'export-from-json'
const XLSX = require('xlsx')
export default {
  name: 'PagosLista',
  components: {
    PagosListaItem,
    BaseNotification2,
    BaseLoader
  },
  data: function () {
    return {
      pagos: ['q'],
      paginado: [],
      conceptos: [],
      servicios: [],
      socios: [],
      archivo: [],
      pageNumber: 1,
      pageCount: 0,
      cargando: false,
      mostrarLastPageBullets: true,
      currentPage: 1,
      sizePaginado: 20,
      sizeBulletPaginado: 5,
      pago: {
        servicio_id: null,
        persona_id: null,
        concepto_id: null,
        fecha_inicio: null,
        fecha_finalizacion: null
      },
      user: {
        tipo_id: JSON.parse(localStorage.getItem('userData')).tipo_id,
        persona_id: JSON.parse(localStorage.getItem('userData')).persona_id,
        nombre: JSON.parse(localStorage.getItem('userData')).nombre,
        apellido: JSON.parse(localStorage.getItem('userData')).apellido,
        dni: JSON.parse(localStorage.getItem('userData')).dni,
        direccion: JSON.parse(localStorage.getItem('userData')).direccion_1,
        provincia: JSON.parse(localStorage.getItem('userData')).provincia_id_1
      },
      errores: {
        fecha_inicio: null,
        fecha_finalizacion: null
      },
      store
    }
  },
  methods: {
    fechaFormato (fecha) {
      const year = fecha.slice(0, 4)
      const month = fecha.slice(5, 7)
      const day = fecha.slice(8, 10)
      return day + '/' + month + '/' + year
    },
    nextPage () {
      this.currentPage++
      console.log(this.currentPage)
      this.sosUltimaPagina()
      console.log(this.paginado)
    },
    prevPage () {
      this.currentPage--
      console.log(this.currentPage)
      this.sosUltimaPagina()
      console.log(this.paginado)
    },
    goToPage (page) {
      this.currentPage = page
      console.log(page)
      this.sosUltimaPagina()
      console.log(this.paginado)
    },
    firstPage () {
      this.currentPage = 1
      this.sosUltimaPagina()
      console.log(this.paginado)
    },
    lastPage () {
      this.currentPage = this.pageCount
      this.sosUltimaPagina()
      console.log(this.paginado)
    },
    sosUltimaPagina () {
      console.log('2222222')
      const start = Math.floor((this.currentPage - 1) / this.sizeBulletPaginado) * this.sizeBulletPaginado
      // const end = start + this.sizeBulletPaginado
      if (this.pageCount - start < this.sizeBulletPaginado) {
        this.mostrarLastPageBullets = false
      } else {
        this.mostrarLastPageBullets = true
      }
    },
    exportData () {
      console.log(this.pagos)
      const data = this.archivo
      // const data = [{ foo: 'foo' }, { bar: 'bar' }]
      // const fileName = 'Listado_de_Pagos'
      // const exportType = 'csv'
      // exportFromJSON({ data, fileName, exportType })
      // exportFromJSON({ data, exportType })
      const workSheet = XLSX.utils.json_to_sheet(data)
      const workBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Listado_de_Pagos')
      XLSX.writeFile(workBook, 'Listado_de_Pagos.xlsx')
    },
    borrarNotificacion () {
      this.store.setStatus({
        msg: null,
        type: null,
        title: null
      })
    },
    refrescarPagos () {
      pagosService
        .traerPagosTodos()
        .then(respuesta => {
          // this.cargando = false
          this.pagos = respuesta
          console.log('Todas los pagos', respuesta)
        })
    },
    buscar (pago) {
      this.cargando = true
      this.errores = {
        fecha_inicio: null,
        fecha_finalizacion: null
      }
      console.log(pago)
      pagosService
        .buscarPagos(pago)
        .then(respuesta => {
          this.cargando = false
          console.log(respuesta)
          if (respuesta.success) {
            console.log('www')
            this.pagos = respuesta.data
            this.pageCount = Math.ceil(this.pagos.length / this.sizePaginado)
            this.pago = {
              servicio_id: null,
              persona_id: null,
              concepto_id: null,
              fecha_inicio: null,
              fecha_finalizacion: null
            }
            this.exportarExcel(respuesta)
            console.log('Busqueda de pagos: ', respuesta)
            this.exportarExcel(respuesta.data)
          } else {
            this.errores = {
              fecha_inicio: null,
              fecha_finalizacion: null,
              ...respuesta.errors
            }
          }
        })
    },
    exportarExcel (data) {
      this.archivo = []
      for (var j = 0; j < data.length; j++) {
        const obj = {}
        obj.Fecha = this.fechaFormato(this.pagos[j].fecha)
        obj.Persona = this.pagos[j].nombre + ' ' + this.pagos[j].apellido
        obj.Perfil = this.pagos[j].perfil
        obj.Email = this.pagos[j].email
        obj.Celular = this.pagos[j].celular
        obj.Provincia = this.pagos[j].provincia
        obj.Direccion_Completa = this.pagos[j].direccion_completa
        obj.Monto = this.pagos[j].monto
        obj.Moneda = this.pagos[j].moneda
        obj.Factura = this.pagos[j].factura
        obj.MercadoPago = this.pagos[j].mercadopago_id
        obj.FormaDePago = this.pagos[j].forma_de_pago
        obj.CuotaNumero = this.pagos[j].cuota
        obj.CantidadDeCuotas = this.pagos[j].cantidad_cuotas
        obj.Servicio = this.pagos[j].servicio
        obj.Concepto = this.pagos[j].concepto
        obj.Descripcion = this.pagos[j].descripcion
        this.archivo.push(obj)
      }
    }
  },
  computed: {
    pagosPaginado: function () {
      const start = (this.currentPage - 1) * this.sizePaginado
      const end = start + this.sizePaginado
      return this.pagos.slice(start, end)
    },
    bulletsPaginado: function () {
      const start = Math.floor((this.currentPage - 1) / this.sizeBulletPaginado) * this.sizeBulletPaginado
      const end = start + this.sizeBulletPaginado
      if (this.pageCount - start < this.sizeBulletPaginado) {
        return this.paginado.slice(this.pageCount - this.sizeBulletPaginado, this.pageCount)
      } else {
        return this.paginado.slice(start, end)
      }
    }
  },
  mounted: function () {
    this.cargando = true
    pagosService
      .traerConceptosDePago()
      .then(respuesta => {
        // this.cargando = false
        this.conceptos = respuesta
        console.log('Conceptos: ', respuesta)
      })
    serviciosService
      .traerServiciosTodos()
      .then(respuesta => {
        // this.cargando = false
        this.servicios = respuesta
        console.log('Servicios: ', respuesta)
      })
    pagosService
      .traerPagosTodos()
      .then(respuesta => {
        this.cargando = false
        this.pagos = respuesta
        console.log('Todas los pagos', respuesta)
        this.pageCount = Math.ceil(this.pagos.length / this.sizePaginado)
        this.exportarExcel(respuesta)
        for (var i = 1; i <= this.pageCount; i++) {
          this.paginado.push(i)
        }
        // for (var j = 0; j < respuesta.length; j++) {
        //   const obj = {}
        //   obj.Fecha = this.fechaFormato(this.pagos[j].fecha)
        //   obj.Persona = this.pagos[j].nombre + ' ' + this.pagos[j].apellido
        //   obj.Monto = this.pagos[j].monto
        //   obj.Moneda = this.pagos[j].moneda
        //   obj.FormaDePago = this.pagos[j].forma_de_pago
        //   obj.Cuota = this.pagos[j].cuota
        //   obj.CantidadDeCuotas = this.pagos[j].cantidad_cuotas
        //   obj.Servicio = this.pagos[j].servicio
        //   obj.Concepto = this.pagos[j].concepto
        //   obj.Descripcion = this.pagos[j].descripcion
        //   this.archivo.push(obj)
        // }
        // console.log(this.archivo)
        // const start = 0
        // const end = 5
        // this.paginado.slice(start, end)
        // console.log(this.paginado.slice(start, end))
      })
    asociadoService
      .listadoPersonasNoExtranjeros()
      .then(respuesta => {
        // this.cargando = false
        this.socios = respuesta
        console.log('Personas: ', respuesta)
        this.socios.map(function (x) {
          // const pepe = x.nombre + ' ' + x.apellido
          // console.log(pepe)
          x.item_data = x.nombre + ' ' + x.apellido
          // return x.item_data = pepe
        })
      })
  }
}
</script>

<style>

</style>
