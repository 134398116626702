<template>
  <div class="contenedor-cpanel">
    <!-- <Header2/> -->
    <section class="contenedor-c">
      <div class="contenedor-titulo">
        <h1>Estadística Ventas</h1>
        <div class="barra"></div>
      </div>
      <VentasListaCarrito
      ></VentasListaCarrito>
    </section>
  </div>
</template>

<script>
import VentasListaCarrito from '../components/VentasListaCarrito.vue'
// import Header2 from '../components/Header2.vue'
export default {
  name: 'EstadisticaVentas',
  components: {
    VentasListaCarrito
    // Header2
  },
  data: function () {
    return {
    }
  }
}
</script>

<style>

</style>
